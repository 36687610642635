/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 50px;
  margin-top: 9px;
  margin-bottom: 9px;
}
@media (min-width: 1025px) {
  div.sub1 .cb-toggle {
    display: none !important;
  }
}
div.sub1 > .item {
  margin: 0 16px;
  position: relative;
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
@media (min-width: 1025px) {
  div.sub1 > .item:hover > .menu,
  div.sub1 > .item:focus > .menu {
    color: #005967;
  }
}
div.sub1 > .item > .menu {
  text-transform: uppercase;
  text-decoration: none;
  color: #669ba4;
}
div.sub1 > .item > .menu.path {
  color: #005967;
}
div.sub2 {
  position: absolute;
  left: -10px;
  top: 100%;
  background: #fff;
  padding: 7px 10px 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  display: none;
  z-index: 3;
}
div.sub2 > .item {
  width: 100%;
}
div.sub2 > .item > .menu {
  text-transform: uppercase;
  text-decoration: none;
  color: #669ba4;
  white-space: nowrap;
}
div.sub2 > .item > .menu.path {
  color: #005967;
}
@media (min-width: 1025px) {
  div.sub2 > .item > .menu:hover,
  div.sub2 > .item > .menu:focus {
    color: #005967;
  }
}
div.sub1 > .item:hover div.sub2 {
  display: block;
}
.desk {
  max-width: 94%;
}
.naviwidth {
  width: calc(100% - 150px);
}
.sidecontent {
  width: 29.16666667%;
}
.cb-layout3 .sidecontent {
  width: 50%;
}
.maincontent {
  float: right;
  width: 66.66666667%;
}
.cb-layout3 .maincontent {
  width: 45.83333333%;
}
.vcard {
  width: auto;
  padding-left: 50px;
  box-sizing: border-box;
}
@media (max-width: 1277px) {
  .vcard {
    padding-left: 3.91542678vw;
  }
}
.openings {
  padding-right: 50px;
  box-sizing: border-box;
}
@media (max-width: 1277px) {
  .openings {
    padding-right: 3.91542678vw;
  }
}
@media (max-width: 1200px) {
  div.sub1 {
    padding-left: 0;
  }
  div.sub1 .item .menu {
    font-size: 16px;
    line-height: 1.75;
  }
  div.sub1 .item.exit div.sub2 {
    left: unset;
    right: -10px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 1.80722892%;
  margin-left: 1.80722892%;
}
.area .part {
  margin-right: 1.80722892%;
  margin-left: 1.80722892%;
  width: 96.38554217%;
}
.area .tiny {
  width: 46.38554217%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .foot,
.area > .slim .part {
  margin-right: 3.61445783%;
  margin-left: 3.61445783%;
}
.area > .slim .part {
  width: 92.77108434%;
}
.area > .slim .tiny {
  width: 42.77108434%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main .foot {
  margin-right: 1.2195122%;
  margin-left: 1.2195122%;
}
.cb-layout2 .main .part {
  margin-right: 1.2195122%;
  margin-left: 1.2195122%;
  width: 97.56097561%;
}
.cb-layout2 .main .tiny {
  width: 47.56097561%;
}
.cb-layout2 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part {
  margin-right: 2.43902439%;
  margin-left: 2.43902439%;
}
.cb-layout2 .main > .slim .part {
  width: 95.12195122%;
}
.cb-layout2 .main > .slim .tiny {
  width: 45.12195122%;
}
.cb-layout3 .side {
  width: 100%;
}
.cb-layout3 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .side .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .side > .slim {
  width: 33.33333333%;
}
.cb-layout3 .side > .slim .foot,
.cb-layout3 .side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout3 .side > .slim .part {
  width: 100%;
}
.cb-layout3 .side > .slim .tiny {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .foot {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
}
.base .part {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
  width: 91.66666667%;
}
.base > .slim {
  width: 50%;
}
.base > .slim .foot,
.base > .slim .part {
  margin-right: 8.33333333%;
  margin-left: 8.33333333%;
}
.base > .slim .part {
  width: 83.33333333%;
}
.base > .slim .tiny {
  width: 83.33333333%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 1.25%;
  margin-left: 1.25%;
  width: 97.5%;
}
.farwest .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.farwest .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest .tiny {
  width: 50%;
}
.farwest > .slim {
  width: 47.5%;
}
.farwest > .slim .foot,
.farwest > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
}
.farwest > .slim .part {
  width: 100%;
}
.farwest > .slim .tiny {
  width: 50%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south .foot {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
}
.south .part {
  margin-right: 4.16666667%;
  margin-left: 4.16666667%;
  width: 91.66666667%;
}
.south .tiny {
  width: 41.66666667%;
}
.south > .slim {
  width: 33.33333333%;
}
.south > .slim .foot,
.south > .slim .part {
  margin-right: 12.5%;
  margin-left: 12.5%;
}
.south > .slim .part {
  width: 75%;
}
.south > .slim .tiny {
  width: 75%;
}
div.main {
  margin-left: -1.875%;
  width: 103.75%;
}
.cb-layout2 div.main {
  width: 102.5%;
  margin-left: -1.25%;
}
div.farnorth {
  margin-left: -1.875%;
  width: 103.75%;
}
div.farwest {
  width: 102.5%;
  margin-left: -1.25%;
}
.cb-layout1 div.main div.pure.slim {
  width: 100%;
}
.cb-layout1 div.main div.pure.slim div.part {
  margin-left: 1.80722892%;
  margin-right: 1.80722892%;
  width: 54.21686747%;
}
.cb-layout1 div.main div.pure.slim div.part.tiny {
  width: 38.55421687%;
}
.cb-layout1 div.main div.seam,
.cb-layout1 div.main div.flat,
.cb-layout1 div.main div.edge {
  margin-left: 1.80722892%;
  margin-right: 1.80722892%;
  width: 96.38554217%;
}
.cb-layout1 div.main div.seam div.part,
.cb-layout1 div.main div.flat div.part,
.cb-layout1 div.main div.edge div.part {
  margin-left: 6.25%;
  margin-right: 6.25%;
  width: 87.5%;
}
.cb-layout1 div.main div.seam div.part.tiny,
.cb-layout1 div.main div.flat div.part.tiny,
.cb-layout1 div.main div.edge div.part.tiny {
  width: 37.5%;
}
.cb-layout1 div.main div.seam.slim,
.cb-layout1 div.main div.flat.slim,
.cb-layout1 div.main div.edge.slim {
  width: 46.38554217%;
}
.cb-layout1 div.main div.seam.slim div.part,
.cb-layout1 div.main div.flat.slim div.part,
.cb-layout1 div.main div.edge.slim div.part {
  margin-left: 12.98701299%;
  margin-right: 12.98701299%;
  width: 74.02597403%;
}
.cb-layout1 div.farnorth div.pure,
.cb-layout1 div.farnorth div.seam,
.cb-layout1 div.farnorth div.flat,
.cb-layout1 div.farnorth div.edge {
  margin-left: 1.80722892%;
  margin-right: 1.80722892%;
  width: 96.38554217%;
}
.cb-layout1 div.farnorth div.pure div.part,
.cb-layout1 div.farnorth div.seam div.part,
.cb-layout1 div.farnorth div.flat div.part,
.cb-layout1 div.farnorth div.edge div.part {
  margin-left: 6.25%;
  margin-right: 6.25%;
  width: 87.5%;
}
.cb-layout1 div.farnorth div.pure div.part.tiny,
.cb-layout1 div.farnorth div.seam div.part.tiny,
.cb-layout1 div.farnorth div.flat div.part.tiny,
.cb-layout1 div.farnorth div.edge div.part.tiny {
  width: 37.5%;
}
.cb-layout1 div.farnorth div.pure.slim,
.cb-layout1 div.farnorth div.seam.slim,
.cb-layout1 div.farnorth div.flat.slim,
.cb-layout1 div.farnorth div.edge.slim {
  width: 46.38554217%;
}
.cb-layout1 div.farnorth div.pure.slim div.part,
.cb-layout1 div.farnorth div.seam.slim div.part,
.cb-layout1 div.farnorth div.flat.slim div.part,
.cb-layout1 div.farnorth div.edge.slim div.part {
  margin-left: 12.98701299%;
  margin-right: 12.98701299%;
  width: 74.02597403%;
}
.cb-layout1 div.side div.seam div.part,
.cb-layout1 div.side div.flat div.part,
.cb-layout1 div.side div.edge div.part {
  margin-left: 14.28571429%;
  margin-right: 14.28571429%;
  width: 71.42857143%;
}
.cb-layout3 div.south div.wide {
  width: 66.66666667%;
}
.cb-layout3 div.south div.wide div.part {
  width: 87.5%;
  margin-left: 6.25%;
  margin-right: 6.25%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */